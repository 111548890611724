@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

$theme-colors: (
  "primary": rgb(94, 150, 174),
  "danger": rgb(213, 0, 0),
  "neutral": rgb(84, 110, 122),
  "light": rgb(255, 255, 255),
  "dark": rgb(0, 33, 113),
);

@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/tables";
@import "./Style/toastr/index";

@import url("https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap");

html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

@keyframes revealer {
  0% {
    clip-path: circle(0);
  }
  100% {
    clip-path: circle(100%);
  }
}

.revealitem {
  clip-path: circle(0);

  &.revealed {
    animation: revealer 0.5s both;
  }
}

a.btn {
  text-decoration: none;
}

td .btn {
  margin: 0 5px;
}

button,
.btn {
  cursor: pointer;
}

.btn {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Nunito Sans, sans-serif;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: #fff;
}

html {
  min-height: 100vh;
  background: #fff;
  font-size: 14px;
}

body,
html,
#root {
  height: 100%;
  line-height: 1.42857;
  font-feature-settings: "tnum", "tnum";
  font-variant: tabular-nums;
  background-color: transparent;
  --main-accent-color: 94, 150, 174;
  --main-accent-color-dark: 0, 33, 113;
  --main-accent-text-color: 255, 255, 255;
}

.theme-light {
  --overlay-color: 255, 255, 255;
  --sidebar-dark-color: 0, 0, 0;
  --hero-dark-alpha: 0.3;
  --link-active-color: 94, 150, 174;
  --link-hover-color: 94, 150, 174;
  --bg-color: 243, 243, 243;
  --input-field-bg: 249, 249, 249;
  --input-field-border: 184, 184, 184;
  --spinner-bg: 255, 255, 255;
  --spinner-border: 0, 0, 0;
  --title-color: 32, 32, 32;
  --text-color: 74, 80, 92;
  --form-card-border: 255, 255, 255;

  --card-border: 150, 150, 156;
  --card-color: 255, 255, 255;
  --card-accent-bg: 230, 230, 250;
  --card-media-bg: 43, 129, 212;
  --card-accent: 255, 255, 255;

  --hero-card-color: 255, 255, 255;
  --hero-card-text-color: 43, 129, 212;
  --hero-card-alpha: 1;

  --error-bg: 211, 168, 95;
  --error-color: 43, 129, 212;
  --header-bg: 255, 255, 255;
  --header-color: 94, 150, 174;
  --table-header-color: 255, 255, 255;

  --pagination-active-bg: 41, 98, 255;
  --pagination-active-color: 255, 255, 255;
  --pagination-active-border: 13, 71, 161;

  --pagination-disabled-bg: 176, 190, 197;
  --pagination-disabled-color: 207, 216, 220;
  --pagination-disabled-border: 144, 164, 174;

  --pagination-bg: 94, 150, 174;
  --pagination-color: 255, 255, 255;
  --pagination-border: 20, 81, 141;

  --pagination-hover-bg: 25, 118, 210;
  --pagination-hover-color: 255, 255, 255;
  --pagination-hover-border: 0, 75, 160;

  --ghostload-color: 220, 220, 220;
  --ghostload-accent: 243, 243, 243;
  --scrollbar-bg: 255, 255, 255;
  --scrollbar-color: 0, 0, 0;
}

.theme-dark {
  --overlay-color: 0, 0, 0;
  --sidebar-dark-color: 0, 0, 0;
  --hero-dark-alpha: 0.8;
  --bg-color: 38, 50, 56;

  --input-field-bg: 0, 0, 0;
  --input-field-border: 120, 120, 120;

  --link-active-color: 94, 150, 174;
  --link-hover-color: 94, 150, 174;

  --spinner-bg: 21, 19, 18;
  --spinner-border: 0, 0, 0;
  --title-color: 224, 214, 214;
  --text-color: 224, 214, 214;

  --form-card-border: 37, 31, 28;

  --card-border: 32, 40, 52;
  --card-color: 18, 19, 21;
  --card-accent-bg: 8, 9, 11;
  --card-media-bg: 0, 10, 18;
  --card-accent: 255, 255, 255;

  --hero-card-color: 0, 10, 18;
  --hero-card-text-color: 255, 255, 255;
  --hero-card-alpha: 1;

  --error-bg: 35, 35, 35;
  --error-color: 255, 255, 255;

  --header-bg: 0, 10, 18;
  --header-color: 255, 255, 255;

  --pagination-active-bg: 41, 98, 255;
  --pagination-active-color: 255, 255, 255;
  --pagination-active-border: 13, 71, 161;

  --pagination-disabled-bg: 44, 46, 51;
  --pagination-disabled-color: 123, 117, 105;
  --pagination-disabled-border: 32, 33, 37;

  --pagination-bg: 20, 21, 25;
  --pagination-color: 13, 71, 161;
  --pagination-border: 30, 31, 35;

  --pagination-hover-bg: 25, 118, 210;
  --pagination-hover-color: 255, 255, 255;
  --pagination-hover-border: 0, 75, 160;

  --ghostload-color: 44, 50, 62;
  --ghostload-accent: 84, 90, 102;
  --scrollbar-bg: 60, 60, 60;
  --scrollbar-color: 120, 120, 120;
}

::-webkit-scrollbar {
  background: RGBA(var(--header-color), 0.2);
  position: absolute;
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: RGBA(var(--header-color), 0.5);
}
::-webkit-scrollbar-thumb:hover {
  background-color: RGBA(var(--header-color), 0.8);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px RGBA(var(--header-color), 0.1);
}

@keyframes ghostLoading {
  0% {
    background-position: -50vmin;
  }
  100% {
    background-position: 100vmin;
  }
}

.ghostLoad {
  animation: ghostLoading 2.5s infinite linear;
  background-color: RGB(var(--ghostload-color));
  background-image: linear-gradient(
    to right,
    RGB(var(--ghostload-color)) 0%,
    RGB(var(--ghostload-accent)) 20%,
    RGB(var(--ghostload-color)) 50%,
    RGBA(var(--ghostload-color)) 100%
  );
  background-repeat: no-repeat;
}

img::selection {
  background-color: transparent;
  color: transparent;
}

.btn {
  padding: 0.5rem 0.7rem;
  font-size: 1rem;
  border-radius: 3px;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3),
    2px 2px 4px 0 rgba(240, 240, 240, 0.15) inset;
}

.hidden-text {
  color: transparent;
}
